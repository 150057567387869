import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { ClearIcon } from "../../assets";
import { Thumb } from "./components";

const ImageSelectionDialog = ({
  images,
  open,
  submitLabel,
  title,
  value,
  onClose,
  onSubmit,
}) => {
  /* STATE */
  const [selected, setSelected] = useState({});

  /* HANDLERS */
  const handleSubmit = () => {
    selected.url !== value && onSubmit(selected.url);
    return onClose();
  };
  const handleSelect = (id) => {
    return setSelected(
      images.https.find((img) => {
        return img.id === id;
      }) || {}
    );
  };

  /* LIFECYCLE HOOKS */
  useEffect(() => {
    images.https &&
      setSelected(
        images.https.find(({ url }) => {
          return url === value;
        }) || {}
      );
  }, [value, images]);

  /* JSX */
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogTitle align='center' disableTypography>
        <Typography variant='h4'>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Thumb
              persistentOverlay
              pointer
              selected={!selected.id}
              onClick={handleSelect}
            >
              <ClearIcon />
            </Thumb>
          </Grid>
          {images.https &&
            images.https.map(({ url, id }) => {
              return (
                <Grid key={id} item xs={12} sm={6} md={3} lg={2}>
                  <Thumb
                    id={id}
                    pic={url}
                    pointer
                    selected={selected.id === id}
                    onClick={handleSelect}
                  />
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify='space-evenly'>
          <Button variant='contained' color='secondary' onClick={onClose}>
            Annuler
          </Button>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            onClick={handleSubmit}
          >
            {submitLabel}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ImageSelectionDialog.propTypes = {
  images: PropTypes.object,
  open: PropTypes.bool.isRequired,
  submitLabel: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
ImageSelectionDialog.defaultProps = {
  open: false,
  title: "Choisissez une image",
  submitLabel: "Modifier",
  onClose: () => {},
  onSubmit: () => {},
};

export default ImageSelectionDialog;

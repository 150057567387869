import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";

/* STYLES */
const useTableStyles = makeStyles((theme) => {
  return {
    // tableContainer: { overflow: "hidden" },
    // tableRow: { display: "flex" },
    // tableCell: { width: "30%", overflow: "hidden" },
    // tooltip: { textAlign: "center" },
    tooltipCursor: { cursor: "help" },
  };
});

/* COMPONENT */
const HeadlessTable = ({ rows }) => {
  /* HOOKS */
  const classes = useTableStyles();

  /* JSX */
  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableBody>
          {(rows || []).map((row, i) => {
            const { description, tip, value } = row || {};
            return tip ? (
              <TableRow key={i} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <Tooltip title={tip} classes={{ tooltip: classes.tooltip }}>
                    <Typography className={classes.tooltipCursor} noWrap>
                      {description}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align='center' style={{ flex: 1 }}>
                  {value}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={i} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <Typography noWrap>{description}</Typography>
                </TableCell>
                <TableCell align='center' style={{ flex: 1 }}>
                  {value}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Table.propTypes = {
  rows: PropTypes.array,
};

export default HeadlessTable;

import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@material-ui/core";
import { useButtonStyles } from "./styles";

const Button = ({ variant, ...props }) => {
  const classes = useButtonStyles();
  return (
    <MuiButton
      className={
        variant ? classes[variant] : classes.root
      }
      {...props}
    />
  );
};

Button.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default Button;

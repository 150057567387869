import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { useFilterStyles, useBackgroundStyles, usePanelStyles } from "./styles";
import { PanelElement, PanelParagraph } from "./components";

const Panel = forwardRef(
  (
    {
      background,
      children,
      displayTitle,
      elements,
      filter,
      fullHeight,
      margin,
      padding,
      panel,
      rtl,
      title,
      wrap,
      handleScrollToTarget,
      hideInNav,
      ...props
    },
    ref
  ) => {
    /* HOOKS */
    const classes = {
      ...usePanelStyles({
        margin,
        padding,
      }),
      ...useBackgroundStyles(background),
      ...useFilterStyles(filter),
    };

    /* JSX */
    return (
      <Grid
        ref={ref}
        className={clsx(
          classes.root,
          background && classes.background,
          filter && classes.filter,
          fullHeight && classes.fullHeight
        )}
        container
        wrap={wrap}
        {...props}
      >
        {elements.map((element, i) => {
          const {
            actions = [],
            type,
            content,
            title: elemTitle,
            ...props
          } = element;
          let lines = [];
          content.split("\n").forEach((line, i) => {
            lines.push(line);
            i < content.split("\n").length - 1 && lines.push(<br key={i} />);
          });

          return type === "paragraph" ? (
            <PanelParagraph
              key={element.id || i}
              // align={rtl && "right"}
              actions={actions.map((action) => {
                let onClick = action.onClick;
                if (typeof onClick === "object") {
                  onClick =
                    onClick.action === "scrollTo"
                      ? () => handleScrollToTarget(action.onClick.target)
                      : onClick;
                }
                return {
                  ...action,
                  onClick,
                };
              })}
              title={elemTitle || title}
              {...props}
            >
              {lines}
            </PanelParagraph>
          ) : (
            <PanelElement key={element.id || i} {...props}>
              {content}
            </PanelElement>
          );
        })}
      </Grid>
    );
  }
);

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  fixedBack: PropTypes.bool,
  fullHeight: PropTypes.bool,
  id: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

Panel.defaultProps = {
  elements: [],
  panel: true,
};

export default Panel;

import { makeStyles } from "@material-ui/core";

const useNavStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: "transparent",
    },
  };
});
export default useNavStyles;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { SketchPicker } from "react-color";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      width: "fill-available",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  };
});

const ColorsOptions = ({ theme, onModify }) => {
  /* DESTRUCTURING PROPS */
  const {
    palette: {
      primary: primaryColors = {},
      secondary: secondaryColors = {},
    } = {},
  } = theme || {};

  /* STATE */
  const [primary, setPrimary] = useState(primaryColors.main);
  const [primaryContrast, setPrimaryContrast] = useState(
    primaryColors.contrastText
  );
  const [secondary, setSecondary] = useState(secondaryColors.main);
  const [secondaryContrast, setsecondaryContrast] = useState(
    secondaryColors.contrastText
  );
  /* HOOKS */
  const classes = useStyles();

  useEffect(() => {
    if (primaryColors && secondaryColors) {
      setPrimary(primaryColors.main);
      setPrimaryContrast(primaryColors.contrastText);
      setSecondary(secondaryColors.main);
      setsecondaryContrast(secondaryColors.contrastText);
    }
  }, [primaryColors, secondaryColors]);

  /* JSX */
  return (
    <Grid container>
      <Typography variant='h3'>Mes couleurs</Typography>
      <Paper className={clsx(classes.paper, classes.primary)}>
        <Typography>Principale</Typography>
        <Grid container>
          <Grid
            container
            direction='column'
            alignItems='center'
            justify='center'
            item
            xs={12}
            sm={6}
          >
            <Typography>Couleur</Typography>
            <SketchPicker
              disableAlpha
              color={primary}
              onChange={(color) => setPrimary(color)}
              onChangeComplete={({ hex }) =>
                onModify("palette/primary/main", hex)
              }
            />
          </Grid>
          <Grid
            container
            direction='column'
            alignItems='center'
            justify='center'
            item
            xs={12}
            sm={6}
          >
            <Typography>Text en contraste</Typography>
            <SketchPicker
              disableAlpha
              color={primaryContrast}
              onChange={(color) => setPrimaryContrast(color)}
              onChangeComplete={({ hex }) =>
                onModify("palette/primary/contrastText", hex)
              }
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={clsx(classes.paper, classes.secondary)}>
        <Typography>Secondaire</Typography>
        <Grid container>
          <Grid
            container
            direction='column'
            alignItems='center'
            justify='center'
            item
            xs={12}
            sm={6}
          >
            <Typography>Couleur</Typography>
            <SketchPicker
              disableAlpha
              color={secondary}
              onChange={(color) => setSecondary(color)}
              onChangeComplete={({ hex }) =>
                onModify("palette/secondary/main", hex)
              }
            />
          </Grid>
          <Grid
            container
            direction='column'
            alignItems='center'
            justify='center'
            item
            xs={12}
            sm={6}
          >
            <Typography>Text en contraste</Typography>
            <SketchPicker
              disableAlpha
              color={secondaryContrast}
              onChange={(color) => setsecondaryContrast(color)}
              onChangeComplete={({ hex }) =>
                onModify("palette/secondary/contrastText", hex)
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ColorsOptions.propTypes = {};

export default ColorsOptions;

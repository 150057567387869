import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { AppBar, Grid, Toolbar } from "@material-ui/core";
import { Button } from "./components";
import { useNavStyles, useFilterStyles, useBackgroundStyles } from "./styles";

const Nav = forwardRef(
  ({ background, filter, classes: propClasses, items = [], position }, ref) => {
    /* HOOKS */
    const classes = {
      ...useNavStyles(),
      ...useBackgroundStyles(background),
      ...useFilterStyles(filter),
    };

    /* JSX */
    return (
      <AppBar
        classes={propClasses}
        className={classes.root}
        color='default'
        component='nav'
        ref={ref}
        position={position || "sticky"}
      >
        <Toolbar
          classes={{
            root: clsx(
              filter && classes.filter,
              background && classes.background
            ),
          }}
        >
          <Grid container justify='space-evenly'>
            {items.map((item) => {
              const { id, label, onClick } = item;
              return (
                <Button id={id} key={id} variant='nav' onClick={onClick} color='inherit'>
                  {label}
                </Button>
              );
            })}
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
);

Nav.propTypes = {
  sticky: PropTypes.bool,
  items: PropTypes.array,
  negativeMargin: PropTypes.bool,
};

Nav.defaultProps = {
  nav: true,
};

export default Nav;

import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { IconButton } from ".";
import { useIntroStyles } from "../../../../../aw/components/Intro/styles";
import { usePanelParagraphStyles } from "../../../../../aw/components/Panel/components/PanelParagraph/styles";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      width: "fill-available",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  };
});

const TextOptions = ({ theme, onModify }) => {
  /* HOOKS */
  const classes = useStyles();
  const introClasses = useIntroStyles();
  const panelClasses = usePanelParagraphStyles();

  const handleModifyTitleFontSize = (element, action) => {
    let { typography: { [element]: { fontSize: currentSize } = {} } = {} } =
      theme || {};
    if (!currentSize) {
      switch (element) {
        case "h1":
          currentSize = "6rem";
          break;
        case "h2":
          currentSize = "3.75rem";
          break;
        case "h3":
          currentSize = "3rem";
          break;
        case "h4":
          currentSize = "2.125rem";
          break;
        case "h5":
          currentSize = "1.5rem";
          break;
        case "h6":
          currentSize = "1.25rem";
          break;
        case "introTitle":
          currentSize = "6rem";
          break;
        case "introSubtitle":
          currentSize = "3rem";
          break;
        case "panelTitle":
          currentSize = "3.75rem";
          break;
        case "panelBody":
          currentSize = "1rem";
          break;
        default:
          break;
      }
    }
    return onModify(
      `typography/${element}/fontSize`,
      action === "increase"
        ? `${parseFloat(currentSize.split("rem")[0]) + 0.25}rem`
        : action === "decrease" &&
            `${parseFloat(currentSize.split("rem")[0]) - 0.25}rem`
    );
  };

  /* JSX */
  return (
    <Grid container>
      <Typography variant='h3'>Formatage de l'introduction</Typography>
      <Paper className={classes.paper}>
        <Grid container alignItems='center' wrap='nowrap'>
          <Grid item>
            <Grid container direction='column' justify='center'>
              <IconButton
                variant='up'
                onClick={() =>
                  handleModifyTitleFontSize("introTitle", "increase")
                }
              />
              <IconButton
                variant='down'
                onClick={() =>
                  handleModifyTitleFontSize("introTitle", "decrease")
                }
              />
            </Grid>
          </Grid>
          <Typography className={introClasses.title} variant='h1' noWrap>
            Titre de l'introduction
          </Typography>
        </Grid>
        <Grid container alignItems='center' wrap='nowrap'>
          <Grid item>
            <Grid container direction='column' justify='center'>
              <IconButton
                variant='up'
                onClick={() =>
                  handleModifyTitleFontSize("introSubtitle", "increase")
                }
              />
              <IconButton
                variant='down'
                onClick={() =>
                  handleModifyTitleFontSize("introSubtitle", "decrease")
                }
              />
            </Grid>
          </Grid>
          <Typography className={introClasses.subtitle} variant='h3' noWrap>
            Sous titre de l'introduction
          </Typography>
        </Grid>
      </Paper>
      <Typography variant='h3'>Formatage des bandeaux</Typography>
      <Paper className={classes.paper}>
        <Grid container alignItems='center' wrap='nowrap'>
          <Grid item>
            <Grid container direction='column' justify='center'>
              <IconButton
                variant='up'
                onClick={() =>
                  handleModifyTitleFontSize("panelTitle", "increase")
                }
              />
              <IconButton
                variant='down'
                onClick={() =>
                  handleModifyTitleFontSize("panelTitle", "decrease")
                }
              />
            </Grid>
          </Grid>
          <Typography className={panelClasses.title} variant='h2' noWrap>
            Titre des bandeaux
          </Typography>
        </Grid>
        <Grid container alignItems='center' wrap='nowrap'>
          <Grid item>
            <Grid container direction='column' justify='center'>
              <IconButton
                variant='up'
                onClick={() =>
                  handleModifyTitleFontSize("panelBody", "increase")
                }
              />
              <IconButton
                variant='down'
                onClick={() =>
                  handleModifyTitleFontSize("panelBody", "decrease")
                }
              />
            </Grid>
          </Grid>
          <Typography className={panelClasses.body} variant='body1' noWrap>
            Texte des bandeaux
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
};

TextOptions.propTypes = {};

export default TextOptions;

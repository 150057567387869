import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { usePanelParagraphStyles } from "./styles";
import { PanelElement } from "..";
import { Button } from "..";

const PanelParagraph = ({
  actions,
  actionsProps,
  children,
  fontColor,
  subtitle,
  title,
  titleAlign,
  titleFont,
  ...elementProps
}) => {
  /* HOOKS */
  const classes = usePanelParagraphStyles({
    fontColor,
    titleFont,
  });

  /* JSX */
  return (
    <PanelElement {...elementProps}>
      <Grid
        className={classes.root}
        container
        direction='column'
        justify='center'
        alignItems={titleAlign === "center" ? "center" : undefined}
        wrap='nowrap'
      >
        {title && (
          <Typography className={classes.title} variant='h2' align={titleAlign}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            className={classes.subtitle}
            variant='h5'
            align={titleAlign}
          >
            {subtitle}
          </Typography>
        )}
        <Typography className={classes.body} variant='body1'>
          {children}
        </Typography>
        {actions && (
          <Grid item container justify='center'>
            {actions.map((button, i) => {
              const { label } = button;
              return (
                <Button key={button.id || i} {...button}>
                  {label}
                </Button>
              );
            })}
          </Grid>
        )}
      </Grid>
    </PanelElement>
  );
};

/* PROP TYPES */
PanelParagraph.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  actionsProps: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  titleAlign: PropTypes.string,
  titleFont: PropTypes.string,
};

export default PanelParagraph;

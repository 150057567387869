import { Helmet } from "react-helmet";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Router } from "./components";
import { AppContext } from "./context";
import { useConfig, useStorage } from "./hooks";

function App() {
  /* HOOKS */
  const { content, settings, theme, update } = useConfig();
  const storage = useStorage();

  /* DESTRUCTURING PROPS */
  const { title, description, fonts } = settings || {};
  const fontsFamilies =
    fonts &&
    Object.values(fonts)
      .map((font) => {
        font = (font || "").replace(/ /g, "+");
        return `family=${font}&`;
      })
      .join();

  /* JSX */
  return (
    <AppContext.Provider value={{ content, settings, storage, theme, update }}>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        {fonts && (
          <link
            href={`https://fonts.googleapis.com/css2?${fontsFamilies}display=swap`}
            rel='stylesheet'
          />
        )}
      </Helmet>
      <ThemeProvider theme={responsiveFontSizes(createMuiTheme(theme))}>
        <Router />
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;

import React from "react";
import PropTypes from "prop-types";
import { Switch as MuiSwitch } from "@material-ui/core";

/* COMPONENT */
const Switch = ({ propName, value, onChange }) => {
  /* HANDLERS */
  const handleChange = () => {
    return onChange(propName, !value);
  };

  /* JSX */
  return (
    <MuiSwitch
      color='primary'
      checked={value}
      inputProps={{ "aria-label": `${propName} checkbox` }}
      name='checkedFilter'
      size='small'
      onChange={handleChange}
    />
  );
};

Switch.propTypes = {
  propName: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
Switch.defaultProps = {
  onChange: () => {},
};

export default Switch;

import { useEffect, useState } from "react";
import { auth } from ".";

const useAuth = () => {
  const [user, setUser] = useState();

  /**
   * Register new user
   * @param {string} email
   * @param {string} password
   * @param {string} name
   * @param {string} firstName
   */
  const signUp = (email, password, sendEmailVerification) => {
    /* VALIDATORS */
    if (!email || typeof email !== "string") {
      throw new Error("Param 'email' must be of type 'string'");
    }
    if (!password || typeof password !== "string") {
      throw new Error("Param 'password' must be of type 'string'");
    }

    return auth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        sendEmailVerification && res.user.sendEmailVerification();
        setUser(res.user);
        return res.user;
      })
      .catch((err) => {
        let error = new Error(err);
        if (err.code === "auth/invalid-email") {
          error.message = "Format de l'adresse mail incorrect";
          error.code = err.code;
          error.field = "email";
        } else if (err.code === "auth/argument-error") {
          error.message = "Format de l'adresse mail incorrect";
          error.code = err.code;
          error.field = "email";
        } else if (err.code === "auth/weak-password") {
          error.message = "6 caractères minimum";
          error.code = err.code;
          error.field = "password";
        } else if (err.code === "auth/email-already-in-use") {
          error.message = "Cette adresse mail est déjà utilisée";
          error.code = err.code;
          error.field = "email";
        }
        throw error;
      });
  };
  /**
   * Sign in user
   * @param {string} email
   * @param {string} password
   */
  const signIn = (email, password) => {
    return auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        setUser(res.user);
        return res.user;
      })
      .catch((err) => {
        let error = new Error(err);
        if (err.code === "auth/invalid-email") {
          error.message = "L'adresse e-mail n'est pas valide";
          error.field = "email";
        } else if (
          err.code === "auth/wrong-password" ||
          err.code === "auth/user-not-found"
        ) {
          error.message = "Email ou mot de passe incorrect";
          error.field = ["email", "password"];
        } else if (err.code === "auth/network-request-failed") {
          error.message = "Vérifiez votre connexion internet";
          error.field = "email";
        }
        throw error;
      });
  };
  /**
   * Sign out user
   */
  const signOut = () => {
    return auth.signOut().then(() => {
      setUser(false);
    });
  };
  /**
   * Delete user
   * @param {string} email
   * @param {string} password
   */
  const remove = (password) => {
    return (
      user &&
      signIn(user.email, password).then(() => {
        return user.delete().then(() => {
          return setUser(false);
        });
      })
    );
  };
  /**
   * Send password reset link via e-mail
   * @param {string} email
   */
  const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email);
  };

  useEffect(() => {
    const unsubsrcibe = auth.onAuthStateChanged((user) => {
      setUser(user || false);
    });
    return () => unsubsrcibe();
  }, []);

  return {
    user,
    signUp,
    signIn,
    signOut,
    resetPassword,
    remove,
  };
};

export default useAuth;

import { useState, useEffect } from "react";
import { storage } from "../firebase";

const useStorage = () => {
  const [file, setFile] = useState();
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  const addFile = (file) => {
    const types = ["image/png", "image/jpeg", "image/jpg"];
    if (file) {
      if (types.includes(file.type)) {
        setError(null);
        setFile(file);
      } else {
        setError("Please select an image file (png or jpg)");
      }
    }
  };

  // runs every time the file value changes
  useEffect(() => {
    if (file) {
      const storageRef = storage.ref(file.name);
      storageRef.put(file).on(
        "state_changed",
        (snap) => {
          const percentage = Math.round(
            (snap.bytesTransferred / snap.totalBytes) * 100
          );
          setProgress(percentage);
        },
        (err) => {
          setError(err);
        },
        async () => {
          const downloadUrl = await storageRef.getDownloadURL();
          setUrl(downloadUrl);
        }
      );
    }
  }, [file]);

  return { progress, url, error, addFile };
};

export default useStorage;

import { useEffect, useState } from "react";
import { database } from "../firebase";

const useLibrary = () => {
  const [images, setImages] = useState({});

  const libraryRoot = "/library";

  const addElement = (folder, element) => {
    return database
      .ref(libraryRoot)
      .child(folder)
      .push(element, (err) => {
        console.warn(err);
      });
  };
  const removeElement = (folder, id) => {
    return database.ref(`${libraryRoot}/${folder}`).child(id).remove();
  };

  /* LIFECYCLE HANDLERS */
  useEffect(() => {
    const libRef = database.ref(libraryRoot);
    libRef.on("value", (snap) => {
      let { https = {} } = snap.val() || {};
      https = Object.values(https).map((http, i) => {
        return {
          url: http,
          id: Object.keys(https)[i],
        };
      });
      setImages((images) => ({ ...images, https }));
    });
    return () => libRef.off();
  }, []);

  return {
    images,
    addElement,
    removeElement,
  };
};

export default useLibrary;

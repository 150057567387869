import React from "react";
import PropTypes from "prop-types";
import {
  IconButton as MuiIconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  AlignLeftIcon,
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignRightIcon,
  DownIcon,
  EditIcon,
  UpIcon,
  HttpIcon,
  RestoreIcon,
  ClearIcon,
} from "../../assets";

const useIconButtonStyles = (margin) => {
  const makeClasses = makeStyles((theme) => {
    if (!Array.isArray(margin)) {
      if (typeof margin === "number") {
        margin = [margin];
      } else if (!margin) {
        margin = [0];
      } else {
        margin = [0];
      }
    }
    return {
      root: {
        margin: theme.spacing(...margin),
      },
      lowerString: {
        textTransform: "none",
      },
    };
  });
  return makeClasses();
};

const IconButton = ({ current, margin, size, variant, ...props }) => {
  /* HOOKS */
  const classes = useIconButtonStyles(margin);

  /* PROPS CONFIG */
  const variantsProps = {
    up: {
      "aria-label": "move up",
      children: <UpIcon fontSize={size === "small" ? "small" : "large"} />,
      color: "primary",
      component: "span",
      size,
    },
    down: {
      "aria-label": "move up",
      children: <DownIcon fontSize={size === "small" ? "small" : "large"} />,
      color: "primary",
      component: "span",
      size,
    },
    textAlign: {
      "aria-label": "toggle align",
      children:
        current === "left" ? (
          <AlignLeftIcon fontSize={size === "small" ? "small" : "large"} />
        ) : current === "center" ? (
          <AlignCenterIcon fontSize={size === "small" ? "small" : "large"} />
        ) : current === "justify" ? (
          <AlignJustifyIcon fontSize={size === "small" ? "small" : "large"} />
        ) : current === "right" ? (
          <AlignRightIcon fontSize={size === "small" ? "small" : "large"} />
        ) : (
          <AlignLeftIcon fontSize={size === "small" ? "small" : "large"} />
        ),
      color: "primary",
      component: "span",
      size,
    },
    edit: {
      "aria-label": "edit",
      children: <EditIcon fontSize={size === "small" ? "small" : "large"} />,
      color: "primary",
      component: "span",
      size,
    },
    http: {
      "aria-label": "http link",
      children: <HttpIcon fontSize={size === "small" ? "small" : "large"} />,
      color: "primary",
      component: "span",
      size,
    },
    restore: {
      "aria-label": "restore",
      children: <RestoreIcon fontSize={size === "small" ? "small" : "large"} />,
      color: "primary",
      component: "span",
      size,
    },
    delete: {
      "aria-label": "restore",
      children: <ClearIcon fontSize={size === "small" ? "small" : "large"} />,
      color: "primary",
      component: "span",
      size,
    },
  };

  let tooltipProps, variantProps;
  if (variantsProps[variant]) {
    let { alt = props.alt, ...vProps } = variantsProps[variant];
    alt && (tooltipProps = { title: alt });
    variantProps = vProps;
  } else {
    variantProps = { variant };
  }

  /* JSX */
  return (
    <>
      {tooltipProps ? (
        <Tooltip className={classes.root} {...tooltipProps}>
          <MuiIconButton
            className={classes.root}
            {...variantProps}
            {...props}
          />
        </Tooltip>
      ) : (
        <MuiIconButton className={classes.root} {...variantProps} {...props} />
      )}
    </>
  );
};

IconButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
};
IconButton.defaultProps = {
  size: "small",
};

export default IconButton;

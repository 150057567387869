/* Native classes extensions */
/**
 * @method addHours Add hours to DateTime Object
 * @method addDays Add days to DateTime Object
 * @method addWeeks Add weeks to DateTime Object
 * @method addMonths Add months to DateTime Object
 * @method addYears Add years to DateTime Object
 * @method dayStart
 * @method dayEnd
 */
class MyDate extends Date {
  /**
   * @desc Add hours to DateTime Object
   * @param {integer} hours hours to add to DateTime object
   * @return {self}
   */
  addHours(hours) {
    this.setHours(this.getHours() + hours);
    return this;
  }
  /**
   * @desc Add days to DateTime Object
   * @param {integer} days days to add to DateTime object
   * @return {self}
   */
  addDays(days) {
    this.setDate(this.getDate() + days);
    return this;
  }
  /**
   * @desc Add weeks to DateTime Object
   * @param {integer} weeks weeks to add to DateTime object
   * @return {self}
   */
  addWeeks(weeks) {
    this.setDate(this.getDate() + weeks * 7);
    return this;
  }
  /**
   * @desc Add months to DateTime Object
   * @param {integer} months months to add to DateTime object
   * @return {self}
   */
  addMonths(months) {
    this.setMonth(this.getMonth() + months);
    return this;
  }
  /**
   * @desc Add years to DateTime Object
   * @param {integer} years years to add to DateTime object
   * @return {self}
   */
  addYears(years) {
    this.setFullYear(this.getFullYear() + years);
    return this;
  }
  /**
   * @desc Set Time to 00:00:00
   * @return {self}
   */
  dayStart() {
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    return this;
  }
  /**
   * @desc Set Time to 23:59:59
   * @return {self}
   */
  dayEnd() {
    this.setHours(23);
    this.setMinutes(59);
    this.setSeconds(59);
    return this;
  }
}

export default MyDate;

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  AppBar,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Drawer,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { ChevronLeftIcon, ChevronRightIcon, MenuIcon } from "../assets";
import { useMiniDrawerStyles } from "../styles";
import { Link } from "react-router-dom";

const MiniDrawer = ({ children, title, menu, bottomMenu }) => {
  /* STATE */
  const [open, setOpen] = useState(false);

  /* HOOKS */
  const theme = useTheme();
  const classes = useMiniDrawerStyles();

  /* HANDLERS */
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Grid container wrap='nowrap'>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(
          classes.drawer,
          open && classes.drawerOpen,
          !open && classes.drawerClose
        )}
        classes={{
          paper: clsx(
            classes.noOverflowX,
            open && classes.drawerOpen,
            !open && classes.drawerClose
          ),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menu.map((item, i) => {
            const { id = i, icon, label, linkTo, onClick } = item;
            return (
              <React.Fragment key={id}>
                {onClick ? (
                  <ListItem button onClick={onClick}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItem>
                ) : (
                  <Link className={classes.link} to={linkTo}>
                    <ListItem button>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={label} />
                    </ListItem>
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </List>
        {bottomMenu && (
          <>
            <div className={classes.filler} />
            <Divider />
            <List>
              {bottomMenu.map((item, i) => {
                const { id = i, icon, label, linkTo, onClick } = item;
                return (
                  <React.Fragment key={id}>
                    {onClick ? (
                      <ListItem button onClick={onClick}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText primary={label} />
                      </ListItem>
                    ) : (
                      <Link className={classes.link} to={linkTo}>
                        <ListItem button>
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText primary={label} />
                        </ListItem>
                      </Link>
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          </>
        )}
      </Drawer>
      <Grid className={classes.content} component='main'>
        <div className={classes.toolbar} />
        {children}
      </Grid>
    </Grid>
  );
};

MiniDrawer.propTypes = {
  bottomMenu: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
};
MiniDrawer.defaultProps = {
  menu: [],
};

export default MiniDrawer;

import { makeStyles } from "@material-ui/core";

const useFooterStyles = makeStyles((theme) => {
  return {
    root: {
      zIndex: 1,
    },
    credits: {
      ...theme.typography.credits,
      overflow: "hidden",
      // zIndex: 10000,
    },
  };
});

export default useFooterStyles;

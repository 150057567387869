import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Grid, makeStyles } from "@material-ui/core";
import { useBackgroundStyles } from "../../../../aw";

const useThumbStyles = makeStyles((theme) => {
  return {
    paper: {
      width: "fill-available",
      margin: theme.spacing(1),
      overflow: "hidden",
      border: `solid transparent 3px`,
      boxSizing: "border-box",
    },
    content: {
      height: 150,
    },
    overlay: {
      height: "100%",
      width: "100%",
    },
    hover: {
      opacity: 0,
      "&:hover": {
        opacity: 1,
      },
      transition: theme.transitions.create(["opacity"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    infos: {
      height: 35,
      width: "100%",
      backgroundColor: "rgba( 0, 0, 0, 0.5 )",
      color: theme.palette.common.white,
      padding: theme.spacing(0, 1),
    },
    pointer: {
      cursor: "pointer",
      "&:hover": {
        borderColor: theme.palette.primary.dark,
      },
    },
    selected: {
      borderColor: theme.palette.primary.main,
    },
  };
});

const Thumb = ({
  children,
  pic,
  persistentOverlay,
  pointer,
  id,
  selected,
  onClick,
}) => {
  /* HOOKS */
  const classes = {
    ...useThumbStyles(),
    ...useBackgroundStyles(pic),
  };

  const handleClick = () => {
    return onClick(id);
  };

  /* JSX */
  return (
    <Grid
      className={clsx(
        classes.paper,
        selected && classes.selected,
        pointer && classes.pointer
      )}
      onClick={handleClick}
    >
      <Grid
        className={clsx(classes.background, classes.content)}
        container
        justify='center'
        alignContent='center'
      >
        <Grid
          className={clsx(classes.overlay, !persistentOverlay && classes.hover)}
          container
          justify='center'
          alignItems='center'
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

Thumb.propTypes = {
  persistentOverlay: PropTypes.bool,
  pic: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func.isRequired,
};
Thumb.defaultProps = {
  persistentOverlay: false,
  pic: {},
  onClick: () => {},
};

export default Thumb;

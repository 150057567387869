import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

/* CONFIG */
const firebaseConfig = {
  apiKey: "AIzaSyCGTeZI-E4S3BKk8L5_Biuh3ZFY-_tPQeM",
  authDomain: "arteroweb.firebaseapp.com",
  databaseURL: "https://arteroweb.firebaseio.com",
  projectId: "arteroweb",
  storageBucket: "arteroweb.appspot.com",
  messagingSenderId: "76507972516",
  appId: "1:76507972516:web:91a747c25eb74f4a2cb731",
};

/* INITIALIZE FIREBASE */
firebase.initializeApp(firebaseConfig);

export default firebase;

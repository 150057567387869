import { makeStyles } from "@material-ui/core";

const usePanelStyles = ({ margin, padding }) => {
  const styles = makeStyles((theme) => {
    return {
      root: {
        overflow: "hidden",
        padding,
        margin,
      },
      fullHeight: {
        minHeight: "100vh",
      },
    };
  });
  return styles();
};

export default usePanelStyles;

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import {
  useBackgroundStyles,
  useFilterStyles,
  useFooterStyles,
} from "./styles";
import { Logo } from "..";

const Footer = forwardRef(
  ({ classes: propsClasses, background, children, filter, logo }, ref) => {
    /* HOOKS */
    const classes = {
      ...useFooterStyles(),
      ...useBackgroundStyles(background),
      ...useFilterStyles(filter),
    };

    /* JSX */
    return (
      <Grid
        classes={propsClasses}
        className={clsx(
          classes.root,
          background && classes.background,
          filter && classes.filter
        )}
        container
        justify='center'
        alignContent='flex-start'
        alignItems='flex-start'
        ref={ref}
      >
        <Grid container>{children}</Grid>
        <Grid item>
          {<Logo {...logo} />}
          <Typography className={classes.credits} align='center'>
            Powered by ArteroWeb
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

Footer.propTypes = {
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  filter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
  logo: PropTypes.object,
  propsClasses: PropTypes.object,
};

Footer.defaultProps = {
  logo: {},
  footer: true,
};

export default Footer;

import React from "react";
import PropTypes from "prop-types";
import { Fab as MuiFab, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AddIcon, EditIcon, ClearIcon, FileCopyIcon } from "../../assets";

const useFabStyles = (margin) => {
  const makeClasses = makeStyles((theme) => {
    if (!Array.isArray(margin)) {
      if (typeof margin === "number") {
        margin = [margin];
      } else if (!margin) {
        margin = [0];
      } else {
        margin = [0];
      }
    }
    return {
      root: {
        margin: theme.spacing(...margin),
      },
      lowerString: {
        textTransform: "none",
      },
    };
  });
  return makeClasses();
};

const Fab = ({ margin, variant, ...props }) => {
  const classes = useFabStyles(margin);

  /* PROPS CONFIG */
  const variantsProps = {
    add: {
      alt: "Ajouter",
      "aria-label": "ajouter",
      color: "primary",
      component: "span",
      children: <AddIcon />,
      size: "small",
    },
    delete: {
      alt: "Supprimer",
      "aria-label": "supprimer",
      color: "secondary",
      component: "span",
      children: <ClearIcon />,
      size: "small",
    },
    edit: {
      alt: "Modifier",
      "aria-label": "modifier",
      color: "primary",
      component: "span",
      children: <EditIcon />,
      size: "small",
    },
    duplicate: {
      alt: "Dupliquer",
      "aria-label": "modifier",
      color: "primary",
      component: "span",
      children: <FileCopyIcon />,
      size: "small",
    },
    moreOptions: {
      "aria-label": "modifier",
      classes: { label: classes.lowerString },
      color: "default",
      component: "span",
      children: (
        <>
          <AddIcon />
          Plus d'options
        </>
      ),
      size: "small",
      variant: "extended",
    },
  };
  let tooltipProps, variantProps;
  if (variantsProps[variant]) {
    const { alt: title, ...props } = variantsProps[variant];
    title && (tooltipProps = { title });
    variantProps = props;
  } else {
    variantProps = { variant };
  }

  /* JSX */
  return (
    <>
      {tooltipProps ? (
        <Tooltip className={classes.root} {...tooltipProps}>
          <MuiFab {...variantProps} {...props} />
        </Tooltip>
      ) : (
        <MuiFab className={classes.root} {...variantProps} {...props} />
      )}
    </>
  );
};

Fab.propTypes = {
  variant: PropTypes.string.isRequired,
};
Fab.defaultProps = {
  variant: "add",
};

export default Fab;

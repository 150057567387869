import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { HeadlessTable, IconButton } from "../../ContentAdmin/components";

/* STYLES */
const useStyles = makeStyles((theme) => {
  return {
    paper: {
      width: "fill-available",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  };
});

/* COMPONENT */
const SiteConfig = ({ settings, onModify, onOpenDialog }) => {
  /* PROPS DESTRUCTURING */
  const { brandName, title, description } = settings || {};

  /* HOOKS */
  const classes = useStyles();

  /* HANDLERS */
  const handleOpenDialog = (props) => {
    onOpenDialog({ open: true, ...props });
  };

  /* TABLE */
  const createRows = (description, value, tip) => ({
    description,
    value,
    tip,
  });
  const rows = [
    createRows(
      "Nom de votre marque",
      <IconButton
        variant='edit'
        onClick={() =>
          handleOpenDialog({
            title: "Modifier le nom de votre marque",
            value: brandName,
            onSubmit: (value) => onModify("settings/brandName", value),
          })
        }
      />,
      "Modifier le nom de votre marque"
    ),
    createRows(
      "Nom du site",
      <IconButton
        variant='edit'
        onClick={() =>
          handleOpenDialog({
            title: "Modifier le nom du site",
            value: title,
            onSubmit: (value) => onModify("settings/title", value),
          })
        }
      />,
      "Modifier le nom du site"
    ),
    createRows(
      "Description du site",
      <IconButton
        variant='edit'
        onClick={() =>
          handleOpenDialog({
            title: "Modifier la desciption du site",
            value: description,
            multiline: true,
            onSubmit: (value) => onModify("settings/description", value),
          })
        }
      />,
      "Modifier la description du site"
    ),
  ];

  /* JSX */
  return (
    <Grid container>
      <Paper className={classes.paper}>
        <Typography>Général</Typography>
        <HeadlessTable rows={rows} />
      </Paper>
    </Grid>
  );
};

SiteConfig.propTypes = {};

export default SiteConfig;

import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { useContext } from "react";
import { AppContext } from "../../context";
import { ColorsOptions, TextOptions } from "./components";

const useThemeAdminStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(1),
      // flex: 1,
    },
    container: {
      padding: theme.spacing(1),
    },
    panelPaper: {
      width: "100%",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
  };
});

const ThemeAdmin = () => {
  /* HOOKS */
  const classes = useThemeAdminStyles();
  const { update, theme } = useContext(AppContext);

  const handleModify = (slug, value) => {
    return update(`/theme/${slug}`, value);
  };

  return (
    <Grid container className={classes.root}>
      <TextOptions theme={theme} onModify={handleModify} />
      <ColorsOptions theme={theme} onModify={handleModify} />
    </Grid>
  );
};

export default ThemeAdmin;

import React, { useContext, useState } from "react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Thumb, IconButton } from "./components";
import { Fab, Loader, SimpleFieldDialog } from "../ContentAdmin/components";
import { AdminContext } from "../../context";

const useLibraryAdminStyles = makeStyles((theme) => {
  return {
    root: { padding: theme.spacing(1) },
    paper: {
      width: "fill-available",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  };
});

const LibraryAdmin = () => {
  /* STATE */
  const [dialog, setDialog] = useState({});

  const {
    library: { images = [], addElement = () => {} /* removeElement */ } = {},
  } = useContext(AdminContext);

  /* HOOKS */
  const classes = useLibraryAdminStyles();

  /* STATE HANDLERS */
  /**
   * Set the dialog state to be be spreaded as props in ImageSelectionDialog component
   * @param {object} props Props to add to Dialog component
   * @returns setDialog
   */
  const handleToggleDialog = (props) => {
    return setDialog(props || {});
  };

  /* HANDLERS */
  const handleSaveElement = (variant, url) => {
    return addElement(variant, url);
  };
  // const handleRemoveElement = (folder, id) => {
  //   return removeElement(folder, id);
  // };

  /* JSX */
  return (
    <Loader waitFor={images}>
      <SimpleFieldDialog {...dialog} onClose={(e) => handleToggleDialog()} />
      <Grid className={classes.root} container>
        <Grid container direction='column'>
          <Grid container item alignItems='center'>
            <Typography variant='h3'>Images</Typography>
          </Grid>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Thumb persistentOverlay>
                  <IconButton
                    variant='http'
                    margin={6}
                    size='medium'
                    onClick={() =>
                      handleToggleDialog({
                        open: true,
                        title: "Ajouter une image par URL",
                        variant: "imagesSelection",
                        onSubmit: (url) => handleSaveElement("https", url),
                      })
                    }
                  />
                </Thumb>
              </Grid>
              {images.https &&
                Object.values(images.https).map(({ url, id }) => {
                  return (
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                      <Thumb key={id} pic={url}>
                        {/* <Grid container justify='space-evenly'>
                          <Fab
                            variant='delete'
                            onClick={() => handleRemoveElement("https", id)}
                          />
                        </Grid> */}
                      </Thumb>
                    </Grid>
                  );
                })}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default LibraryAdmin;

import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { IconButton, Loader, HeadlessTable, Switch } from ".";

const useNaveOptionsStyles = makeStyles((theme) => {
  return {
    paper: {
      width: "fill-available",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  };
});

const IntroOptions = ({ intro, onChangeDialog, onModify }) => {
  /* PROPS DESTRUCTURING */
  const {
    background = null,
    filter = false,
    fullHeight = false,
    display = false,
    title,
    subtitle,
  } = intro || {};

  /* HOOKS */
  const classes = useNaveOptionsStyles();

  /* HANDLERS */
  const handleOpenDialog = (props) => {
    return onChangeDialog({
      open: true,
      ...props,
    });
  };
  const handleModify = (propName, value) => {
    return onModify("intro", propName, value);
  };

  /* TABLE */
  const createRows = (description, value, tip) => ({ description, value, tip });
  const rows = [
    createRows(
      "Afficher",
      <Switch propName={"display"} value={display} onChange={handleModify} />,
      "Si activé l'intro sera visible sur le site"
    ),
    createRows(
      "Plein écran",
      <Switch
        propName={"fullHeight"}
        value={fullHeight}
        onChange={handleModify}
      />,
      "Si activé le bandeau prendra la hauteur complète de l'écran"
    ),
    createRows(
      "Filtre",
      <Switch propName={"filter"} value={filter} onChange={handleModify} />,
      "Affiche un filtre entre le l'arrière plan et le contenu du bandeau"
    ),
    createRows(
      "Arrière plan",
      <IconButton
        variant='edit'
        onClick={() =>
          handleOpenDialog({
            title: "Modifier l'arrière plan de l'introduction",
            value: background,
            variant: "imagesSelection",
            onSubmit: (img) => handleModify("background", img),
          })
        }
      />,
      "Arrière plan du bandeau d'introduction"
    ),
    createRows(
      "Titre",
      <IconButton
        variant='edit'
        onClick={() =>
          handleOpenDialog({
            title: "Modifier le titre de l'introduction",
            value: title,
            variant: "simpleField",
            onSubmit: (title) => handleModify("title", title),
          })
        }
      />,
      "Arrière plan du bandeau d'introduction"
    ),
    createRows(
      "Sous titre",
      <IconButton
        variant='edit'
        onClick={() =>
          handleOpenDialog({
            title: "Modifier le sous titre de l'introduction",
            value: subtitle,
            variant: "simpleField",
            onSubmit: (subtitle) => handleModify("subtitle", subtitle),
          })
        }
      />,
      "Arrière plan du bandeau d'introduction"
    ),
  ];

  /* JSX */
  return (
    <Grid container>
      <Paper className={classes.paper}>
        <Typography>Options de l'introduction</Typography>
        <Loader waitFor={intro} differentFrom={undefined}>
          <HeadlessTable rows={rows} />
        </Loader>
      </Paper>
    </Grid>
  );
};

IntroOptions.propTypes = {
  intro: PropTypes.object,
  onChangeDialog: PropTypes.func,
  onModify: PropTypes.func.isRequired,
};
IntroOptions.defaultProps = {
  onChangeDialog: () => {},
  onModify: () => {},
};

export default IntroOptions;

import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { HeadlessTable, IconButton, Loader, Switch } from ".";

/* STYLES */
const useNavOptionsStyles = makeStyles((theme) => {
  return {
    paper: {
      width: "fill-available",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    tableContainer: { overflow: "hidden" },
    tableCell: { padding: theme.spacing(1, 0, 1, 1) },
  };
});

/* COMPONENT */
const NavOptions = ({ nav, panels, onChangeDialog, onModify }) => {
  /* PROPS DESTRUCTURING */
  const { background = null, filter = false, onIntro = false } = nav || {};

  /* HOOKS */
  const classes = useNavOptionsStyles();

  /* HANLDERS */
  const handleOpenDialog = (props) => {
    return onChangeDialog({
      open: true,
      ...props,
    });
  };
  const handleModifyNav = (propName, value) => {
    return onModify("nav", propName, value);
  };
  const handleModifyPanel = (i, propName, value) => {
    return onModify(`panels/${i}`, propName, value);
  };

  /* TABLES */
  const createOptionsTableRows = (description, value, tip) => ({
    description,
    value,
    tip,
  });
  const rows = [
    createOptionsTableRows(
      "Filtre",
      <Switch propName='filter' value={filter} onChange={handleModifyNav} />,
      "Affiche un filtre entre le l'arrière plan et le contenu du bandeau"
    ),
    createOptionsTableRows(
      "Arrière plan",
      <IconButton
        variant='edit'
        onClick={() =>
          handleOpenDialog({
            title: "Modifier l'arrière plan de la navigation",
            value: background,
            variant: "imagesSelection",
            onSubmit: (img) => handleModifyNav("background", img),
          })
        }
      />,
      "Arrière plan du bandeau d'introduction"
    ),
    createOptionsTableRows(
      "Sur l'intro",
      <Switch propName='onIntro' value={onIntro} onChange={handleModifyNav} />,
      "Si activé la navigation sera placée sur l'introduction"
    ),
  ];
  const createRows = (hideInNav, title, displayTitle, panelIndex) => ({
    hideInNav,
    title,
    displayTitle,
    panelIndex,
  });
  const rows1 = (panels || [])
    .filter((panel) => panel.display)
    .map((panel, i) => {
      const { hideInNav, title, displayTitle } = panel;
      return createRows(hideInNav, title, displayTitle, i);
    });

  /* JSX */
  return (
    <Grid container>
      <Paper className={classes.paper}>
        <Typography>Options de la barre de navigation</Typography>
        <Loader waitFor={nav} differentFrom={undefined}>
          <HeadlessTable rows={rows} />
        </Loader>
      </Paper>
      <Paper className={classes.paper}>
        <Typography>Affichage des bandeaux</Typography>
        <Loader waitFor={panels}>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Titre</TableCell>
                  <TableCell>Afficher</TableCell>
                  <TableCell>Nom affiché</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1 &&
                  rows1.map((row, i) => {
                    const { hideInNav, title, displayTitle, panelIndex } = row;
                    const titleValue = displayTitle || title;
                    return (
                      <TableRow key={i} className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          {title}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography>
                            <Switch
                              propName={"hideInNav"}
                              value={!hideInNav}
                              onChange={(propName, value) =>
                                handleModifyPanel(i, propName, !value)
                              }
                            />
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Grid container wrap='nowrap' alignItems='center'>
                            <IconButton
                              variant='edit'
                              margin={[0, 1]}
                              onClick={() =>
                                handleOpenDialog({
                                  required: true,
                                  title: "Nom affiché dans la navigation",
                                  variant: "simpleField",
                                  value: titleValue,
                                  onSubmit: (value) =>
                                    handleModifyPanel(
                                      panelIndex,
                                      "displayTitle",
                                      value
                                    ),
                                })
                              }
                            />
                            {titleValue}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Loader>
      </Paper>
    </Grid>
  );
};

NavOptions.propTypes = {
  onModify: PropTypes.func.isRequired,
};
NavOptions.defaultProps = {
  onModify: () => {},
};

export default NavOptions;

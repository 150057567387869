import React from "react";
import PropTypes from "prop-types";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { PhotoCamera } from "../../assets";

const useFileButtonStyles = (margin) => {
  const makeClasses = makeStyles((theme) => {
    if (!Array.isArray(margin)) {
      if (typeof margin === "number") {
        margin = [margin];
      } else if (!margin) {
        margin = [0];
      } else {
        margin = [0];
      }
    }
    return {
      root: {
        margin: theme.spacing(...margin),
      },
    };
  });
  return makeClasses();
};

const FileButton = ({ tip, margin, size, onInputFile, ...props }) => {
  /* HOOKS */
  const classes = useFileButtonStyles(margin);

  /* HANDLERS */
  const handleChange = (e) => {
    return onInputFile(e.target.files[0]);
  };

  /* JSX */
  return (
    <>
      <input
        accept='image/*'
        style={{ display: "none" }}
        id='icon-button-file'
        type='file'
        onChange={handleChange}
      />
      <label htmlFor='icon-button-file' className={classes.root}>
        {tip ? (
          <Tooltip title={tip}>
            <IconButton
              color='primary'
              aria-label='upload picture'
              component='span'
              size={size}
              {...props}
            >
              <PhotoCamera fontSize={size} />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            color='primary'
            aria-label='upload picture'
            component='span'
            size={size}
            {...props}
          >
            <PhotoCamera fontSize={size} />
          </IconButton>
        )}
      </label>
    </>
  );
};

FileButton.propTypes = {
  onInputFile: PropTypes.func.isRequired,
};
FileButton.defaultProps = {
  onInputFile: () => {},
};

export default FileButton;

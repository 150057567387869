import React, { cloneElement, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { animateScroll } from "react-scroll";
import { Grid } from "@material-ui/core";
import { usePanelsScrollNavStyles } from "../styles";
import { Footer } from "../..";

/* COMPONENT */
const PanelsScrollNav = ({ components, introOnTop }) => {
  /* STATE */
  // TODO : test with useState(undefined)
  const [navHeight, setNavHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  /* REFS */
  const refs = {
    content: useRef(),
    footer: useRef(),
    nav: useRef(),
  };

  /* HOOKS */
  const classes = usePanelsScrollNavStyles({
    navHeight,
    footerHeight,
  });

  /* HANDLERS */
  const handleScrollToTarget = (e) => {
    const target =
      typeof e === "string"
        ? e === "top"
          ? 0
          : e === "bottom"
          ? document.body.clientHeight
          : e
        : e.currentTarget.id;
    animateScroll.scrollTo(
      typeof target === "number"
        ? target
        : refs.content.current.children.namedItem(target).offsetTop
    );
  };

  /* CHILDREN */
  let { intro, nav, footer, panels, others } = components;
  if (intro) {
    intro = cloneElement(intro, {
      key: "intro",
      classes: classes.introMargin,
    });
  }
  if (nav) {
    nav = cloneElement(nav, {
      key: "nav",
      ref: refs.nav,
      classes: {
        root: clsx(
          nav.props.onIntro
            ? classes.onIntro
            : nav.props.onContent && classes.onContent
        ),
      },
      items: panels
        .filter((panel) => !panel.props.hideInNav)
        .map((panel) => {
          return {
            id: panel.props.id,
            label:
              panel.props.displayTitle || panel.props.title || panel.props.id,
            onClick: handleScrollToTarget,
          };
        }),
    });
  }
  if (footer) {
    let { logo } = footer.props;
    if (logo && logo.onClick) {
      const { action, target } = logo.onClick;
      if (action === "scrollTo") {
        logo.onClick = () => handleScrollToTarget(target);
      }
    }
    footer = cloneElement(footer, {
      ref: refs.footer,
      classes: footer.props.onLastPanel
        ? {
            root: classes.onLastPanel,
          }
        : undefined,
      logo,
    });
  } else {
    footer = <Footer />;
  }
  if (panels.length) {
    panels = panels.map((panel, i) => {
      const lastPadding =
        footer && footer.props.onLastPanel && i === panels.length - 1;
      return cloneElement(panel, {
        key: panel.props.id,
        padding: `${navHeight}px 0 ${
          typeof lastPadding === "boolean" && lastPadding
            ? footerHeight + navHeight
            : navHeight
        }px 0`,
      });
    });
  }
  if (others.length) {
    others = others.map((compo, i) => {
      return cloneElement(compo, { key: compo.props.id || i });
    });
  }
  /* LIFECYCLE HOOKS */
  useEffect(() => {
    refs.nav.current && setNavHeight(refs.nav.current.clientHeight);
    refs.footer.current && setFooterHeight(refs.footer.current.clientHeight);
  }, [components, refs.nav, refs.footer]);

  /* JSX */
  return (
    <>
      {intro}
      {nav}
      <Grid
        ref={refs.content}
        className={classes.content}
        component='main'
        container
      >
        {[...panels, ...others]}
      </Grid>
      {footer}
    </>
  );
};

/* PROPS TYPES */
PanelsScrollNav.propTypes = {
  components: PropTypes.object,
  introOnTop: PropTypes.bool,
};
PanelsScrollNav.defaultProps = {
  components: {},
  introOnTop: false,
};

export default PanelsScrollNav;

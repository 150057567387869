import { useEffect, useState } from "react";
import { database } from "../firebase";

const useConfig = () => {
  /* STATE */
  const [content, setContent] = useState();
  const [savedConfigsIndex, setSavedConfigsIndex] = useState();
  const [settings, setSettings] = useState();
  const [theme, setTheme] = useState();

  /* CONFIG */
  const root = "/config";

  /* ACTIONS */
  const fetchContent = (slug) => {
    return database
      .ref(`${root}/content${slug || ""}`)
      .once("value")
      .then((res) => {
        return res.val();
      });
  };
  // TODO : make update more efficient by using ref.update instead of ref.set
  const update = (slug, value) => {
    return database
      .ref(root)
      .child(`${slug || ""}`)
      .set(value);
  };
  const saveCurrentConfig = (name) => {
    const id = database.ref("/savedConfigs").push().getKey();
    const proms = [
      database.ref(`/savedConfigs/${id}`).set({ content, settings, theme }),
      database
        .ref(`/savedConfigs/index/${id}`)
        .set({ date: Date.now(), name: name || null }),
    ];
    return Promise.all(proms);
  };
  const removeSavedConfig = (id) => {
    const proms = [
      database.ref(`/savedConfigs/${id}`).remove(),
      database.ref(`/savedConfigs/index/${id}`).remove(),
    ];
    return Promise.all(proms);
  };
  const restoreSavedConfig = (id) => {
    return database
      .ref(`/savedConfigs/${id}`)
      .once("value")
      .then((res) => {
        const config = res.val();
        return database.ref(`${root}`).set(config);
      });
  };

  /* LYFECICLE HOOKS */
  useEffect(() => {
    const configRef = database.ref("/config");
    configRef.on("value", (snap) => {
      const { content, settings, theme } = snap.val() || {};
      setContent({ intro: {}, nav: {}, panels: [], ...(content || {}) });
      setSettings(settings || {});
      setTheme(theme || {});
      return;
    });
    return () => configRef.off();
  }, []);
  useEffect(() => {
    const savedConfigsIndex = database.ref("/savedConfigs/index");
    savedConfigsIndex.on("value", (snap) => {
      const index = snap.val() || {};
      setSavedConfigsIndex(index);
      return;
    });
    return () => savedConfigsIndex.off();
  }, []);

  /* RETURNED OBJECT */
  return {
    content,
    savedConfigsIndex,
    settings,
    theme,
    fetchContent,
    removeSavedConfig,
    restoreSavedConfig,
    saveCurrentConfig,
    update,
  };
};

export default useConfig;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import { useLogInStyles } from "../../styles";

const LogIn = ({ errors: err, onSignIn, onCancel }) => {
  /* STATE */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(err);

  /* HOOKS */
  const classes = useLogInStyles();

  /* HANDLERS */
  const handleChangeEmail = (e) => {
    return setEmail(e.target.value);
  };
  const handleChangePass = (e) => {
    return setPassword(e.target.value);
  };
  const handleCancel = () => {
    return onCancel();
  };
  const handleSignIn = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
    if (!email.length || !password.length) {
      !email.length &&
        setErrors((err) => ({
          ...err,
          email: "Champs requis",
        }));
      !password.length &&
        setErrors((err) => ({
          ...err,
          password: "Champs requis",
        }));
      setLoading(false);
      return;
    } else {
      return onSignIn(email, password);
    }
  };

  /* LIFECYCLE HOOKS */
  useEffect(() => {
    setErrors(err);
    setLoading(false);
  }, [err]);

  /* JSX */
  return (
    <Grid
      className={classes.root}
      container
      alignItems='center'
      justify='center'
    >
      <Paper>
        <form noValidate>
          <Grid
            className={classes.container}
            container
            direction='column'
            alignItems='center'
          >
            <span className={classes.field}>
              <TextField
                placeholder='Adresse e-mail'
                type='email'
                value={email}
                error={errors.email ? true : false}
                helperText={errors.email}
                onChange={handleChangeEmail}
              />
            </span>
            <span className={classes.field}>
              <TextField
                type='password'
                placeholder='Mot de passe'
                value={password}
                error={errors.password ? true : false}
                helperText={errors.password}
                onChange={handleChangePass}
              />
            </span>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  className={classes.button}
                  onClick={handleSignIn}
                  type='submit'
                >
                  Connexion
                </Button>
                <Button className={classes.button} onClick={handleCancel}>
                  Retour
                </Button>
              </>
            )}
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

LogIn.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
LogIn.defaultProps = {
  auth: () => {},
  errors: {},
  onSignIn: () => {},
  onCancel: () => {},
};

export default LogIn;

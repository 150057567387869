import { makeStyles } from "@material-ui/styles";

const useFilterStyles = ({
  color,
  fontColor,
  opacity,
} = {}) => {
  const styles = makeStyles((theme) => {
    return {
      filter: {
        position: "relative",
        color:
          fontColor || theme.palette.primary.contrastText,
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "block",
          backgroundColor:
            color || theme.palette.primary.main,
          opacity:
            typeof opacity === "number"
              ? opacity
              : 0.5,
        },
        "& *": {
          zIndex: 1,
        },
      },
    };
  });
  return styles();
};
export default useFilterStyles;

export const filterChildrenByProp = (children, prop) => {
  try {
    if (Array.isArray(children)) {
      const results = children.filter((child) => child.props[prop]);
      return results || null;
    } else {
      return children.props[prop] ? [children] : [];
    }
  } catch (err) {
    return console.error(err);
  }
};
export const findChildrenByProp = (children, prop) => {
  try {
    if (Array.isArray(children)) {
      const results = children.find((child) => child.props[prop]);
      return results || null;
    } else {
      return children.props[prop] ? children : null;
    }
  } catch (err) {
    return console.error(err);
  }
};
export const filterChildrenOutByProps = (children, props) => {
  try {
    if (Array.isArray(children)) {
      const results = children.filter(
        (child) => !props.some((prop) => child.props[prop])
      );
      return results || [];
    } else {
      return !props.find((prop) => children.props[prop]) ? [children] : [];
    }
  } catch (err) {
    return console.error(err);
  }
};

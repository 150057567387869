import React, { useContext, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { AdminContext, AppContext } from "../../context";
import {
  FooterOptions,
  ImageSelectionDialog,
  IntroOptions,
  NavOptions,
  PanelOptions,
  SimpleFieldDialog,
} from "./components";

const useContentAdminStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(1),
    },
    container: {
      padding: theme.spacing(1),
    },
    panelPaper: {
      width: "100%",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
  };
});

const ContentAdmin = () => {
  /* STATE */
  const [dialog, setDialog] = useState({});

  /* HOOKS */
  const classes = useContentAdminStyles();
  const { content, update } = useContext(AppContext);
  const { footer, nav, intro, panels } = content || {};
  const { library } = useContext(AdminContext);
  const { images } = library;

  /* STATE HANLDERS */
  /**
   * Set the dialog state to be be spreaded as props in ImageSelectionDialog component
   * @param {object} props Props to add to Dialog component
   * @returns setDialog
   */
  const handleToggleDialog = (props) => {
    return setDialog(props || {});
  };

  /* HANDLERS */
  /**
   * Add new empty panel to the bottom of panels array
   * @returns Update database
   */
  const handleAddPanel = () => {
    const { panels = [] } = content;
    const usedIds = panels.map((panel) => panel.id);
    let id = "new-|-1";
    while (usedIds.includes(id)) {
      const idElements = id.split("-|-");
      id = `${idElements[0]}-|-${parseInt(idElements[1]) + 1}`;
    }
    return update(`/content/panels/${panels.length}`, {
      id,
      title: "Nouveau bandeau",
      align: "left",
      elements: [
        {
          type: "paragraph",
          content: "Hello World !",
        },
      ],
    });
  };
  /**
   * Duplicates a panel and adds it to the bottom of panels array
   * @param {object} panel Panel to be duplicated
   * @returns Update database
   */
  const handleDuplicatePanel = (panel) => {
    const usedIds = content.panels.map((panel) => panel.id);
    let id = `${panel.id.split("-|-")[0]}-|-1`;
    while (usedIds.includes(id)) {
      const idElements = id.split("-|-");
      id = `${idElements[0]}-|-${parseInt(idElements[1]) + 1}`;
    }
    return update(`/content/panels/${content.panels.length}`, {
      ...panel,
      id,
    });
  };
  /**
   * Remove specific panel by its index in database panels array
   * @param {integer} index Panel's index
   * @returns Update database
   */
  const handleRemovePanel = (index) => {
    content.panels.splice(index, 1);
    return update("/content/panels", content.panels);
  };
  /**
   * Move specific panel up or down its array
   * @param {integer} index Index of the panel to be moved
   * @param {string} move Move panel up (i -1) or down (i + 1) the array
   * @returns Update database
   */
  const handleMovePanel = (index, move) => {
    const { panels } = content;
    if (move === "up" && index > 0) {
      panels.splice(index - 1, 0, panels.splice(index, 1)[0]);
      return update(`/content/panels`, panels);
    } else if (index < panels.length && move === "down") {
      panels.splice(index + 1, 0, panels.splice(index, 1)[0]);
      return update(`/content/panels`, panels);
    }
    return;
  };
  /**
   * Modify a prop on a specific element
   * @param {string} slug Slug for the element to modify
   * @param {string} propName Prop to modify
   * @param {*} value New value for the prop
   * @returns update()
   */
  const handleModifyProp = (slug, propName, value) => {
    (value === undefined || value === false) && (value = null);
    return update(`/content/${slug}/${propName}`, value);
  };

  /* JSX */
  return (
    <Grid className={classes.root} container>
      {dialog.variant === "simpleField" ? (
        <SimpleFieldDialog {...dialog} onClose={(e) => handleToggleDialog()} />
      ) : (
        dialog.variant === "imagesSelection" && (
          <ImageSelectionDialog
            {...dialog}
            images={images}
            onClose={(e) => handleToggleDialog()}
          />
        )
      )}
      <Typography variant='h3'>Introduction</Typography>
      <IntroOptions
        intro={intro}
        onChangeDialog={handleToggleDialog}
        onModify={handleModifyProp}
      />
      <Typography variant='h3'>Navigation</Typography>
      <NavOptions
        nav={nav}
        panels={panels}
        onChangeDialog={handleToggleDialog}
        onModify={handleModifyProp}
      />
      <Typography variant='h3'>Bandeaux</Typography>
      <Grid container>
        {(panels || []).map((panel, i) => {
          return (
            <PanelOptions
              key={panel.id || i}
              index={i}
              panel={panel}
              onChangeDialog={handleToggleDialog}
              onDuplicate={handleDuplicatePanel}
              onRemove={handleRemovePanel}
              onModify={handleModifyProp}
              onMove={handleMovePanel}
            />
          );
        })}
        <PanelOptions addNewPanel onAdd={handleAddPanel} />
      </Grid>
      <Typography variant='h3'>Pied de page</Typography>
      <FooterOptions
        footer={footer}
        onModify={handleModifyProp}
        onOpenDialog={handleToggleDialog}
      />
    </Grid>
  );
};

export default ContentAdmin;

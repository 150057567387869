import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Container,
  Grid,
} from "@material-ui/core";
import {
  useBackgroundStyles,
  useFilterStyles,
  usePanelElementStyles,
} from "./styles";

const PanelElement = ({
  background,
  children,
  container,
  filter,
  padding,
  shrink,
  xs,
  sm,
  md,
  lg,
  justify,
  ...props
}) => {
  /* HOOKS */
  const classes = {
    ...usePanelElementStyles({
      padding,
    }),
    ...useBackgroundStyles(background),
    ...useFilterStyles(filter),
  };

  /* JSX */
  return (
    <Grid
      alignItems='center'
      className={clsx(
        classes.root,
        !(xs || sm || md || lg) && classes.flex,
        background && classes.background,
        shrink && classes.shrink,
        filter && classes.filter
      )}
      container
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      {...props}
    >
      {container ? (
        <Container maxWidth={container}>
          <Grid container justify={justify}>
            {children}
          </Grid>
        </Container>
      ) : (
        children
      )}
    </Grid>
  );
};

/* PROP TYPES */
PanelElement.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  background: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  filter: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

export default PanelElement;

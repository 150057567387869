import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Fab, HeadlessTable, IconButton, SimpleFieldDialog, Switch } from ".";

const usePanelOptionsStyles = makeStyles((theme) => {
  return {
    panelPaper: {
      width: "fill-available",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    descriptionContainer: {
      height: "100%",
    },
  };
});

const PanelOptions = ({
  addNewPanel,
  index,
  panel,
  onAdd,
  onChangeDialog,
  onDuplicate,
  onRemove,
  onModify,
  onMove,
}) => {
  /* PROPS DESTRUCTURING */
  const {
    align,
    background = null,
    display = false,
    elements,
    filter = false,
    fullHeight = false,
    id,
    title,
  } = panel;

  /* HOOKS */
  const classes = usePanelOptionsStyles();

  /* HANDLERS */
  const handleOpenDialog = (props) => {
    return onChangeDialog({
      open: true,
      ...props,
    });
  };
  const handleDuplicate = () => {
    return onDuplicate({ ...panel, index });
  };
  const handleRemove = () => {
    return onRemove(parseInt(index));
  };
  const handleToggleAlign = () => {
    const alignements = ["left", "center", "right"];
    const aIndex = alignements.findIndex((al) => al === align);
    const newAlign =
      aIndex + 1 < alignements.length
        ? alignements[aIndex + 1]
        : alignements[0];
    return onModify(`panels/${index}`, "align", newAlign);
  };
  const handleModify = (propName, value) => {
    return onModify(`panels/${index}`, propName, value);
  };
  const handleMove = (move) => {
    return onMove(parseInt(index), move);
  };

  /* TABLE */
  const createRows = (description, value, tip) => ({ description, value, tip });
  const rows = [
    createRows(
      "Afficher",
      <Switch propName={"display"} value={display} onChange={handleModify} />,
      "Si activé le bandeau sera visible sur le site"
    ),
    createRows(
      "Position",
      <>
        <IconButton variant='up' onClick={() => handleMove("up")} />
        {index + 1}
        <IconButton variant='down' onClick={() => handleMove("down")} />
      </>,
      "Position à laquelle le bandeau sera affiché sur le site"
    ),
    createRows(
      "Plein écran",
      <Switch
        propName={"fullHeight"}
        value={fullHeight}
        onChange={handleModify}
      />,
      "Si activé le bandeau prendra la hauteur complète de l'écran"
    ),
    createRows(
      "Filtre",
      <Switch propName={"filter"} value={filter} onChange={handleModify} />,
      "Affiche un filtre entre le l'arrière plan et le contenu du bandeau"
    ),
    createRows(
      "Alignement",
      <IconButton
        variant='textAlign'
        current={align}
        onClick={handleToggleAlign}
      />,
      "Alignement à resepcter pour le contenu du bandeau"
    ),
    createRows(
      "Contenu",
      <IconButton
        size='small'
        variant='edit'
        onClick={() =>
          handleOpenDialog({
            multiline: true,
            variant: "simpleField",
            title: "Modifier le contenu du bandeau",
            value: elements[0].content,
            onSubmit: (img) => handleModify("elements/0/content", img),
          })
        }
      />,
      "Ajouter ou modifier le contenu de ce bandeau"
    ),
    createRows(
      "Arrière plan",
      <IconButton
        variant='edit'
        size='small'
        onClick={() =>
          handleOpenDialog({
            title: "Modifier l'arrière plan du bandeau",
            value: background,
            variant: "imagesSelection",
            onSubmit: (img) => handleModify("background", img),
          })
        }
      />,
      "Modifier l'arrière plan"
    ),
  ];

  /* JSX */
  return (
    <Grid item sm={6} lg={3} container>
      <Paper className={classes.panelPaper}>
        {addNewPanel ? (
          <Grid
            className={classes.descriptionContainer}
            container
            justify='center'
            alignItems='center'
          >
            <Fab margin={5} variant='add' size='small' onClick={onAdd} />
          </Grid>
        ) : (
          <Grid
            className={classes.descriptionContainer}
            container
            justify='space-between'
            direction='column'
          >
            <Grid container alignItems='center' justify='center' wrap='nowrap'>
              <Typography variant='h4' align='center' noWrap>
                {title || id}
              </Typography>
              <IconButton
                margin={1}
                alt='Modifier'
                variant='edit'
                onClick={() =>
                  handleOpenDialog({
                    required: true,
                    title: "Titre du bandeau",
                    value: title,
                    variant: "simpleField",
                    onSubmit: (value) => handleModify("title", value),
                  })
                }
              />
            </Grid>
            <HeadlessTable rows={rows} />
            {/* <Grid container alignItems='center' justify='center'>
              <Fab margin={[2, 1, 1, 1]} variant='moreOptions' />
            </Grid> */}
            <Grid container alignItems='center' justify='space-around'>
              <Fab
                margin={2}
                variant='delete'
                onClick={handleRemove}
                panel-index={index}
              />
              <Fab
                margin={2}
                variant='duplicate'
                onClick={handleDuplicate}
                panel-index={index}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

PanelOptions.propTypes = {
  /* Required props */
  id: PropTypes.string,
  /* Optional props */
  addNewPanel: PropTypes.bool,
  background: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.string,
  ]),
  filter: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.string,
  ]),
  title: PropTypes.string,
  index: PropTypes.number,
  /* Callbacks */
  onAdd: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
};
PanelOptions.defaultProps = {
  addNewPanel: false,
  panel: {},
  onAdd: () => {},
  onDuplicate: () => {},
  onRemove: () => {},
  onModify: () => {},
};

export default PanelOptions;

import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { HeadlessTable, IconButton, Switch } from ".";

const useNaveOptionsStyles = makeStyles((theme) => {
  return {
    paper: {
      width: "fill-available",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    // tableContainer: { overflow: "hidden" },
    // tableCell: { padding: theme.spacing(1, 0, 1, 1) },
    // tooltip: { textAlign: "center" },
    // tooltipCursor: { cursor: "help" },
  };
});

const FooterOptions = ({ background, footer, onModify, onOpenDialog }) => {
  /* PROPS DESTRUCTURING */
  const { filter = false, onLastPanel = false } = footer || {};
  /* HOOKS */
  const classes = useNaveOptionsStyles();

  /* HANDLERS */
  const handleOpenDialog = (props) => {
    return onOpenDialog({
      open: true,
      ...props,
    });
  };
  const handleModify = (propName, value) => {
    return onModify("footer", propName, value);
  };

  /* TABLE */
  const createRows = (description, value, tip) => ({ description, value, tip });
  const rows = [
    createRows(
      "Filtre",
      <Switch propName='filter' value={filter} onChange={handleModify} />,
      "Affiche un filtre entre le l'arrière plan et le contenu du bandeau"
    ),
    createRows(
      "Arrière plan",
      <IconButton
        variant='edit'
        onClick={() =>
          handleOpenDialog({
            variant: "imagesSelection",
            title: "Modifier l'arrière plan de la navigation",
            value: background,
            onSubmit: (img) => handleModify("background", img),
          })
        }
      />,
      "Arrière plan du bandeau d'introduction"
    ),
    createRows(
      "Sur le contenu",
      <Switch
        propName='onLastPanel'
        value={onLastPanel}
        onChange={handleModify}
      />,
      "Si activé le pied de page s'affichera sur le contenu"
    ),
  ];

  /* JSX */
  return (
    <Grid container>
      <Paper className={classes.paper}>
        <Typography>Options du pied de page</Typography>
        <HeadlessTable rows={rows} />
      </Paper>
    </Grid>
  );
};

FooterOptions.propTypes = {
  onModify: PropTypes.func.isRequired,
};
FooterOptions.propTypes = {
  onModify: () => {},
};

export default FooterOptions;

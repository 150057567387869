import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { MiniDrawer, PanelsScrollNav } from "./components";
import { useRootStyles } from "./styles";
import {
  filterChildrenByProp,
  filterChildrenOutByProps,
  findChildrenByProp,
} from "../../utils";

/* COMPONENT */
const Template = ({ children, variant, ...props }) => {
  /* HOOKS */
  const classes = useRootStyles();

  /* CHILDREN */
  const components = {
    intro: findChildrenByProp(children, "intro"),
    nav: findChildrenByProp(children, "nav"),
    footer: findChildrenByProp(children, "footer"),
    panels: filterChildrenByProp(children, "panel"),
    others: filterChildrenOutByProps(children, [
      "intro",
      "nav",
      "footer",
      "panel",
    ]),
  };

  /* JSX */
  return (
    <Grid className={classes.root} container alignContent='flex-start'>
      {variant === "default" ? (
        <PanelsScrollNav components={components} {...props} />
      ) : variant === "miniDrawer" ? (
        <MiniDrawer {...props}>{children}</MiniDrawer>
      ) : undefined}
    </Grid>
  );
};

/* PROPS TYPES */
Template.propTypes = {
  /**
   * Component's children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Template variant
   */
  variant: PropTypes.oneOf(["default", "panelsScrollNav", "miniDrawer"])
    .isRequired,
};
Template.defaultProps = {
  children: [],
  variant: "default",
};

export default Template;

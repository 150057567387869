import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

const SimpleFieldDialog = ({
  multiline,
  open,
  required,
  submitLabel,
  title,
  value: val,
  onClose,
  onSubmit,
}) => {
  /* STATE */
  const [value, setValue] = useState(val);
  const [error, setError] = useState();

  /* HANDLERS */
  const handleValidate = (e) => {
    e.preventDefault();
    if (required && (!value || !value.length)) {
      return setError("Ce champs ne peut pas être vide");
    } else {
      onSubmit(value);
      setError();
      onClose();
      return setValue();
    }
  };
  const handleClose = () => {
    setValue();
    setError();
    return onClose();
  };
  const handleChange = (e) => {
    return setValue(e.target.value);
  };

  /* LYFECICLE HOOKS */
  useEffect(() => {
    setValue(val);
  }, [val]);

  /* JSX */
  return (
    <Dialog open={open} onClose={handleClose}>
      <form noValidate>
        <DialogTitle align='center' disableTypography>
          <Typography variant='h4'>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            error={error ? true : false}
            fullWidth
            helperText={error}
            value={value}
            multiline={multiline}
            rows={multiline ? 4 : undefined}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Grid container justify='space-evenly'>
            <Button variant='contained' color='secondary' onClick={onClose}>
              Annuler
            </Button>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              onClick={handleValidate}
            >
              {submitLabel}
            </Button>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SimpleFieldDialog.propTypes = {
  multiline: PropTypes.bool,
  open: PropTypes.bool,
  required: PropTypes.bool,
  submitLabel: PropTypes.string,
  value: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
SimpleFieldDialog.defaultProps = {
  open: false,
  submitLabel: "Modifier",
  onClose: () => {},
  onSubmit: () => {},
};

export default SimpleFieldDialog;

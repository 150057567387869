import firebase from "./config";

export const auth = firebase.auth();
export const database = firebase.database();
export const storage = firebase.storage();
export const users = {
  save: (uid, email, name, firstName) => {
    return database.ref(`/users/${uid}`).set({
      email,
      name,
      firstName,
      createdAt: new Date().getTime(),
    });
  },
  fetch: (user) => {
    return database
      .ref(`users/${user.uid}`)
      .once("value")
      .then((res) => {
        return res.val() || {};
      });
  },
};

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useAuth, useLibrary } from "../../hooks";
import { AdminContext, AppContext } from "./context";
import { Loader, LogIn, Template } from "./components";
import {
  BackIcon,
  IntroIcon,
  LibraryIcon,
  LogOutIcon,
  SettingsIcon,
  ThemeIcon,
} from "./assets";

/* COMPONENT */
const Admin = ({ children }) => {
  /* CONTEXT */
  const { settings } = useContext(AppContext);
  const { brandName } = settings || {};

  /* STATE */
  const [errors, setErrors] = useState();

  /* HOOKS */
  const auth = useAuth();
  const library = useLibrary();

  /* CALLBACKS */
  const onSignIn = (email, password) => {
    auth.signIn(email, password).catch((err) => {
      const { message, field } = err;
      let errors = {};
      if (Array.isArray(field)) {
        console.log(err);
        field.forEach((f) => {
          return (errors[f] = message);
        });
      } else {
        errors[field] = message;
      }
      return setErrors(errors);
    });
  };

  /* CONFIG */
  const menu = [
    {
      id: "content",
      label: "Contenu",
      icon: <IntroIcon />,
      linkTo: "/admin/content",
    },
    {
      id: "theme",
      label: "Thème",
      icon: <ThemeIcon />,
      linkTo: "/admin/theme",
    },
    {
      id: "library",
      label: "Bibliothèque",
      icon: <LibraryIcon />,
      linkTo: "/admin/library",
    },
    {
      id: "settings",
      label: "Paramètres",
      icon: <SettingsIcon />,
      linkTo: "/admin/settings",
    },
  ];
  const bottomMenu = [
    {
      id: "back",
      label: "Retour au site",
      icon: <BackIcon />,
      linkTo: "/",
    },
    {
      id: "logout",
      label: "Déconnexion",
      icon: <LogOutIcon />,
      onClick: () => auth.signOut(),
    },
  ];

  // TODO : Panels programmable (heures click impressions)
  /* JSX */
  return (
    <AdminContext.Provider value={{ auth, library }}>
      <Loader waitFor={auth.user} differentFrom={undefined} fullScreen>
        {auth.user ? (
          <Template
            title={brandName || "ArteroWeb"}
            menu={menu}
            bottomMenu={bottomMenu}
            variant='miniDrawer'
          >
            {children}
          </Template>
        ) : (
          <LogIn errors={errors} onSignIn={onSignIn} />
        )}
      </Loader>
    </AdminContext.Provider>
  );
};

Admin.protoTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Admin;

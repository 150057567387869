import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

/* STYLES */
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
  },
  spinner: {
    margin: theme.spacing(2),
    color: theme.palette.primary.light,
  },
  fullScreen: {
    height: "100vh",
  },
}));

/* COMPONENT */
const Loader = ({ children, waitFor, differentFrom, fullScreen }) => {
  const [loaded, setLoaded] = useState(false);

  /* HOOKS */
  const classes = useStyles();

  /* LIFECYCLE HOOKS */
  useEffect(() => {
    if (
      (Array.isArray(waitFor) &&
        !(waitFor.findIndex((param) => param === differentFrom) > 1)) ||
      waitFor !== differentFrom
    ) {
      setLoaded(true);
    } else {
      setLoaded(false);
    }
  }, [waitFor, differentFrom]);

  /* JSX */
  return loaded ? (
    children
  ) : (
    <Grid
      className={clsx(classes.root, fullScreen && classes.fullScreen)}
      item
      container
      justify='center'
      alignItems='center'
      direction='column'
    >
      {/* {logo && <Logo variant={logoVariant} />} */}
      <CircularProgress className={classes.spinner} />
    </Grid>
  );
};

export default Loader;

import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { IconButton } from ".";
import { useConfig } from "../../../../../hooks";

/* STYLES */
const useSettingsStyles = makeStyles((theme) => {
  return {
    paper: {
      width: "fill-available",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    tableContainer: { overflow: "hidden" },
    tableCell: { overflow: "hidden" },
    tooltip: { textAlign: "center" },
    tooltipCursor: { cursor: "help" },
  };
});

const SavedConfigs = ({ onOpenDialog }) => {
  /* HOOKS */
  const classes = useSettingsStyles();
  const {
    savedConfigsIndex,
    saveCurrentConfig,
    removeSavedConfig,
    restoreSavedConfig,
  } = useConfig({});

  /* ACTIONS */
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minuts = date.getMinutes();
    return `${day.toString().length > 1 ? day : `0${day}`}/${
      month.toString().length > 1 ? month : `0${month}`
    }/${year} ${hours.toString().length > 1 ? hours : `0${hours}`}:${
      minuts.toString().length > 1 ? minuts : `0${minuts}`
    }`;
  };

  /* HANDLERS */
  const handleToggleDialog = () => {
    return onOpenDialog({
      open: true,
      variant: "simpleField",
      title: "Choisissez le nom de la configuration à sauvegarder",
      onSubmit: handleSaveConfig,
    });
  };
  const handleSaveConfig = (configName) => {
    return saveCurrentConfig(configName);
  };
  const handleRemoveConfig = (id) => {
    return removeSavedConfig(id);
  };
  const handleRestoreConfig = (id) => {
    return restoreSavedConfig(id);
  };

  /* TABLE CONTENT */
  const createRows = (id, configName, date) => ({
    id,
    configName,
    date,
  });
  const rows = Object.values(savedConfigsIndex || {}).map(
    ({ date, name: configName }, i) => {
      return createRows(
        Object.keys(savedConfigsIndex)[i],
        configName,
        formatDate(date)
      );
    }
  );

  /* JSX */
  return (
    <Grid container>
      <Paper className={classes.paper}>
        <Typography>Sauvegardes du site</Typography>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align='center'>Restaurer</TableCell>
                <TableCell align='center'>Supprimer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(({ id, configName, date }, i) => {
                return (
                  <TableRow key={i} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      <Typography noWrap>{id}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography noWrap>{configName}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>{date}</TableCell>
                    <TableCell className={classes.tableCell} align='center'>
                      <IconButton
                        variant='restore'
                        onClick={() => handleRestoreConfig(id)}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align='center'>
                      <IconButton
                        variant='delete'
                        onClick={() => handleRemoveConfig(id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justify='center'>
          <Button
            color='primary'
            variant='contained'
            onClick={() =>
              handleToggleDialog({
                open: true,
                variant: "simpleField",
                title: "Choisissez le nom de la configuration à sauvegarder",
                onSubmit: handleSaveConfig,
              })
            }
          >
            Sauvegarder
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

SavedConfigs.propTypes = {};

export default SavedConfigs;

import { makeStyles } from "@material-ui/styles";

const useLogInStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(1),
  },
  field: {
    margin: theme.spacing(1),
  },
  root: {
    height: "100vh",
  },
}));

export default useLogInStyles;

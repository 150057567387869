import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

// TODO : make default

const useStyles = makeStyles((theme) => {
  return {
    scaleHover: {
      "&:hover": {
        transform: "scale(1.2)",
      },
    },
    shadowHover: {
      "&:hover": {
        textShadow: `0 0 10px ${theme.palette.text.primary}`,
        "-webkit-filter": "drop-shadow(0 0 25px rgba(0,0,0,1))",
        filter: "url(#drop-shadow)",
      },
    },
    title: {
      margin: theme.spacing(2, 0),
    },
    body: {
      margin: theme.spacing(1, 0),
    },
    logo: {
      margin: theme.spacing(1),
      maxHeight: 75,
      maxWidth: 75,
      transition: theme.transitions.create(
        ["text-shadow", "transform", "filter"],
        {
          duration: theme.transitions.duration.standard,
        }
      ),
      "&:hover": {
        cursor: "pointer",
      },
    },
    logoText: {
      margin: theme.spacing(2),
      transition: theme.transitions.create(["text-shadow", "transform"], {
        duration: theme.transitions.duration.standard,
      }),
      "&:hover": {
        textShadow: `0 0 10px ${theme.palette.text.primary}`,
        transform: "scale(1.2)",
        cursor: "pointer",
      },
    },
  };
});

const Logo = ({
  alt,
  src,
  text,
  scaleHover,
  shadowHover,
  onClick,
  ...props
}) => {
  /* HOOKS */
  const classes = useStyles();

  /* JSX */
  return (
    <Grid container justify='center'>
      {(src ? true : false) && (
        <img
          className={clsx(
            classes.logo,
            scaleHover && classes.scaleHover,
            shadowHover && classes.shadowHover
          )}
          alt={alt || "Logo"}
          src={src}
          onClick={onClick}
          {...props}
        />
      )}
      {(text ? true : false) && (
        <Typography
          className={clsx(
            classes.logoText,
            scaleHover && classes.scaleHover,
            shadowHover && classes.shadowHover
          )}
          variant='h3'
          onClick={onClick}
        >
          {text}
        </Typography>
      )}
    </Grid>
  );
};

Logo.propTypes = {};

export default Logo;

import { makeStyles } from "@material-ui/styles";

const useIntroStyles = ({ fullHeight } = {}) => {
  const styles = makeStyles((theme) => {
    return {
      root: {
        minHeight: fullHeight ? "100vh" : 0,
      },
      logoImg: {
        height: "100%",
        width: "100%",
        objectFit: "contain",
      },
      circled: {
        borderRadius: "50%",
        padding: theme.spacing(9, 9),
        boxShadow: "0px 0px 10px 5px rgba(255, 255, 255, .5);",
      },
      title: {
        ...(theme.typography.introTitle || {}),
      },
      subtitle: {
        ...(theme.typography.introSubtitle || {}),
      },
    };
  });
  return styles();
};

export default useIntroStyles;

import { useContext } from "react";
import { Footer, Intro, Nav, Panel, Template } from "./components";
import { AppContext } from "./context";

// TODO : BrandName || ArteroWeb in footer and intro

/* COMPONENT */
const Main = () => {
  /* CONTEXT */
  const {
    settings: { brandName } = {},
    content: {
      panels,
      nav,
      intro: { display: displayIntro , ...introProps } = {},
      footer: { logo: { text, ...logo } = {}, ...footer } = {},
    } = {},
  } = useContext(AppContext);

  /* CHILDREN */
  const renderChildren = () => {
    let children = [];
    if (panels) {
      panels.forEach((panel, i) => {
        const { display, ...panelProps } = panel;
        return (
          display &&
          children.push(
            <Panel key={panel.id || `panel-${i}`} {...panelProps} />
          )
        );
      });
    }
    if ((panels || []).filter((panel) => !panel.hideInNav).length) {
      children.push(<Nav key={"nav"} onContent {...nav} />);
    }
    if (displayIntro) {
      children.push(<Intro key={"intro"} {...introProps} />);
    }
    if (footer) {
      children.push(
        <Footer
          key={"footer"}
          {...footer}
          logo={{ ...logo, text: text || brandName }}
        />
      );
    }
    return children;
  };

  /* JSX */
  return <Template>{renderChildren()}</Template>;
};

export default Main;

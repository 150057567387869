import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import { useFilterStyles, useIntroStyles, useBackgroundStyles } from "./styles";

const Intro = forwardRef(
  (
    {
      children,
      background,
      filter,
      fullHeight,
      title,
      subtitle,
      logoImg,
      classes: customClasses,
      circled,
    },
    ref
  ) => {
    /* HOOKS */
    const classes = {
      ...useIntroStyles({
        fullHeight,
      }),
      ...useBackgroundStyles(background),
      ...useFilterStyles(filter),
    };

    /* JSX */
    return (
      <Grid
        ref={ref}
        className={clsx(
          classes.root,
          background && classes.background,
          filter && classes.filter,
          customClasses
        )}
        container
      >
        {children ? (
          children
        ) : (
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
          >
            {logoImg && (
              <Grid
                item
                xs={8}
                md={6}
                lg={4}
                container
                justify='center'
                alignContent='center'
              >
                <img
                  src={logoImg}
                  alt='Boketto, semons des graines et des idées'
                  className={classes.logoImg}
                />
              </Grid>
            )}
            <Grid item className={clsx(circled && classes.circled)}>
              {title && (
                <Typography
                  className={classes.title}
                  variant='h1'
                  align='center'
                >
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography
                  className={classes.subtitle}
                  variant='h3'
                  align='center'
                >
                  {subtitle}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
);

Intro.propTypes = {
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  body: PropTypes.string,
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  fullHeight: PropTypes.bool,
};

Intro.defaultProps = {
  intro: true,
};

export default Intro;

import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  Main,
  Admin,
  ContentAdmin,
  LibraryAdmin,
  SettingsAdmin,
  ThemeAdmin,
} from "./components";

const Router = () => {
  /* JSX */
  return (
    <BrowserRouter>
      <Switch>
        <Route path={["/", "/home"]} exact>
          <Main />
        </Route>
        <Route path='/admin'>
          <Admin>
            <Switch>
              <Route path='/admin/content' exact>
                <ContentAdmin />
              </Route>
              <Route path='/admin/theme' exact>
                <ThemeAdmin />
              </Route>
              <Route path='/admin/library' exact>
                <LibraryAdmin />
              </Route>
              <Route path='/admin/settings' exact>
                <SettingsAdmin />
              </Route>
              <Redirect to='/admin/content' />
            </Switch>
          </Admin>
        </Route>
        <Redirect to='/home' />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;

import { makeStyles } from "@material-ui/styles";

const useButtonStyles = makeStyles((theme) => {
  return {
    boketto: {
      margin: theme.spacing(1),
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "50vh",
      width: 250,
      "&:hover": {
        boxShadow: `0px 0px 10px ${theme.palette.secondary.main}`,
        backgroundColor:
          theme.palette.secondary.main,
        color: theme.palette.primary.dark,
      },
    },
  };
});

export default useButtonStyles;

import { makeStyles } from "@material-ui/core";

const usePanelParagraphStyles = ({ fontColor, titleFont } = {}) => {
  const styles = makeStyles((theme) => {
    return {
      root: {
        padding: theme.spacing(5),
        color: fontColor,
      },
      title: {
        fontFamily: titleFont && titleFont,
        color: fontColor,
        padding: theme.spacing(2, 0),
        ...(theme.typography.panelTitle || {}),
      },
      subtitle: {
        color: fontColor,
        padding: theme.spacing(2, 0, 4),
      },
      body: {
        ...(theme.typography.panelBody || {}),
      },
    };
  });
  return styles();
};

export default usePanelParagraphStyles;

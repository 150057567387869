import { makeStyles } from "@material-ui/styles";

const useBackgroundStyles = (props) => {
  let background, backgroundColor;
  if (typeof props === "string") {
    background = `url(${props}) center/cover`;
  } else if (typeof props === "object") {
    const { fixed, attachment } = props;
    if (props.background) {
      background = `url(${props.background})`;
      attachment
        ? (background += ` ${attachment}`)
        : (background += " center/cover");
      fixed && (background += " fixed");
    }
    backgroundColor = props.color;
  }

  const styles = makeStyles((theme) => {
    return {
      background: {
        background,
        backgroundColor,
      },
    };
  });
  return styles();
};
export default useBackgroundStyles;

import React, { useContext, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { AppContext } from "../../context";
import { SimpleFieldDialog } from "../Dialogs";
import { SavedConfigs, SiteConfig } from "./components";

/* STYLES */
const useSettingsStyles = makeStyles((theme) => {
  return {
    root: { padding: theme.spacing(1) },
  };
});

/* COMPONENT */
const SettingsAdmin = () => {
  /* STATE */
  const [dialog, setDialog] = useState({});

  /* HOOKS */
  const classes = useSettingsStyles();
  const { settings, update } = useContext(AppContext);

  /* STATE HANDLERS */
  /**
   * Set the dialog state to be be spreaded as props in ImageSelectionDialog component
   * @param {object} props Props to add to Dialog component
   * @returns setDialog
   */
  const handleToggleDialog = (props) => {
    setDialog(props || {});
  };
  /**
   * Modify a slug in database content ref
   * @param {string} slug Slug to modify
   * @param {*} value New value for the chosen slug
   * @returns updateContent()
   */
  const handleModifySlug = (slug, value) => {
    (value === undefined || value === false) && (value = null);
    return update(`/${slug}`, value);
  };

  /* JSX */
  return (
    <Grid className={classes.root} container>
      <SimpleFieldDialog {...dialog} onClose={handleToggleDialog} />
      <Typography variant='h3'>Paramètres</Typography>
      <SiteConfig
        settings={settings}
        onModify={handleModifySlug}
        onOpenDialog={handleToggleDialog}
      />
      <SavedConfigs onOpenDialog={handleToggleDialog} />
    </Grid>
  );
};

export default SettingsAdmin;

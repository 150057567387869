import { makeStyles } from "@material-ui/core";

const usePanelElementStyles = ({
  padding,
} = {}) => {
  const styles = makeStyles((theme) => {
    return {
      root: {
        padding,
      },
      flex: {
        flex: 1,
      },
      shrink: {
        flexGrow: 0,
      },
    };
  });
  return styles();
};

export default usePanelElementStyles;
